.touch-page {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(145deg, var(--dark-purple), var(--bg-purple));
}

.touch-header {
  height: 200px;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: rgba(107, 70, 193, 0.2);
  border-bottom: 1px solid var(--primary-purple);
  z-index: 100;
  position: relative;
}

.devices-status {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.device-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4A5568;
}

.status-dot.active {
  background-color: #48BB78;
}

.status-dot.error {
  background-color: #e74c3c;
}

.device-name {
  color: var(--light-purple);
  font-size: 0.9rem;
}

.touch-container {
  position: relative;
  flex: 1;
  overflow: hidden;
  background: rgba(107, 70, 193, 0.1);
  touch-action: none;
  cursor: crosshair;
}

.trail-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.9);
}

.trail-path {
  fill: none;
  stroke: var(--light-purple);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-opacity: 0.8;
  filter: drop-shadow(0 0 5px rgba(159, 122, 234, 0.6));
}

.touch-indicator {
  position: absolute;
  width: 24px;
  height: 24px;
  background: var(--light-purple);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  box-shadow: 0 0 15px rgba(159, 122, 234, 0.8);
  z-index: 2;
}

.back-link {
  color: var(--light-purple);
  text-decoration: none;
  font-size: 1rem;
}

.back-link:hover {
  text-decoration: underline;
}

/* Prevent text selection during touch/drag */
.touch-container {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@media (max-width: 768px) {
  .touch-header {
    padding: 0.8rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  .device-name {
    font-size: 0.8rem;
  }
}

.devices-list {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.device-item {
  background: rgba(107, 70, 193, 0.2);
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disconnect-button {
  background-color: #e53e3e;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.disconnect-button:hover {
  background-color: #c53030;
}

.status-text {
  color: var(--light-purple);
  font-size: 1rem;
}

.speed-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
  color: var(--light-purple);
}

.speed-slider {
  width: 200px;
  height: 4px;
  -webkit-appearance: none;
  appearance: none;
  background: rgba(159, 122, 234, 0.3);
  border-radius: 2px;
  outline: none;
}

.speed-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: var(--light-purple);
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.1s;
}

.speed-slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
}

.vertical-line {
  stroke: var(--light-purple);
  stroke-width: 0.5;
  stroke-opacity: 0.5;
  stroke-dasharray: 2 2;
}

.intersection-point {
  fill: var(--light-purple);
  stroke: none;
  r: 4;
  filter: drop-shadow(0 0 3px rgba(159, 122, 234, 0.8));
}

.target-line {
  stroke: #9370db;
  stroke-width: 0.5;
  stroke-dasharray: 2 1;
}

.sparkle-line {
  stroke: #fff;
  stroke-width: 0.3;
  transform-origin: center;
}

.sparkle-group {
  animation: sparkle-rotate 1s linear infinite;
}

@keyframes sparkle-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.particle {
  mix-blend-mode: screen;
  filter: blur(1px);
}

.control-sliders {
  display: flex;
  gap: 20px;
  margin: 10px 0;
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.slider-container label {
  font-size: 0.9em;
  color: #666;
}

.slider-container input[type="range"] {
  width: 150px;
}

.particles-svg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
} 