:root {
  --primary-purple: #6B46C1;
  --dark-purple: #2D3748;
  --light-purple: #9F7AEA;
  --bg-purple: #1A202C;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--bg-purple);
  color: white;
  min-height: 100vh;
  width: 100%;
  font-family: system-ui, -apple-system, sans-serif;
}

#root {
  min-height: 100vh;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
