.home-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background: linear-gradient(145deg, var(--dark-purple), var(--bg-purple));
  padding: 1rem;
}

h1 {
  color: var(--light-purple);
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.connect-button {
  background-color: var(--primary-purple);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.connect-button:hover {
  background-color: var(--light-purple);
  transform: translateY(-2px);
}

.connect-button:active {
  transform: translateY(0);
}

/* Mobile optimization */
@media (max-width: 768px) {
  .home-container {
    padding: 2rem 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  .connect-button {
    width: 80%;
    max-width: 300px;
    padding: 1.2rem;
  }
}

.devices-list {
  width: 100%;
  max-width: 600px;
  margin: 2rem 0;
}

.devices-list h2 {
  color: var(--light-purple);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.device-card {
  background: rgba(107, 70, 193, 0.2);
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.device-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.device-name {
  color: var(--light-purple);
  font-size: 1.1rem;
}

.device-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Toggle switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--primary-purple);
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.remove-button {
  background-color: #e53e3e;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.remove-button:hover {
  background-color: #c53030;
}

.start-button {
  background-color: var(--light-purple);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
  margin-top: 1rem;
}

.start-button:hover {
  background-color: var(--primary-purple);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .device-card {
    padding: 0.8rem;
  }

  .device-name {
    font-size: 1rem;
  }

  .switch {
    width: 50px;
    height: 28px;
  }

  .slider:before {
    height: 20px;
    width: 20px;
  }

  input:checked + .slider:before {
    transform: translateX(22px);
  }
}

.mapping-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.mapping-slider {
  width: 100px;
  margin: 5px 0;
} 